import { ChevronRightIcon } from '@heroicons/react/outline'
import { useMediaQuery } from '@mantine/hooks'
import dayjs from 'dayjs'
import { IKDTPlans } from 'features/kdt/types'
import { IKlass } from 'features/klass/types'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { IKlassData } from '.'

interface ISchoolData {
  data: IKlassData
}

const SchoolScheduleCard = ({ data }: ISchoolData) => {
  const router = useRouter()
  const isTablet = useMediaQuery('(max-width:1024px)')

  const applyLabel = (rowData: IKlass | IKDTPlans) => {
    let label: string = ''
    if (rowData.label === 'applyEarly') {
      return (label = '모집 예정')
    } else {
      if (dayjs().isBefore(rowData.applyStartedAt) && 'courseType' in rowData) {
        return (label = '사전 알림 신청')
      } else if (dayjs().isBetween(rowData.applyStartedAt, rowData.applyEndedAt)) {
        return (label = '모집중')
      }
    }
    return label
  }

  const hasApplyData = useMemo(
    () => data.data.some(v => dayjs().isBetween(v.applyStartedAt, v.applyEndedAt)),
    [data.data],
  )

  return (
    <article className="relative mb-4 box-border h-fit overflow-hidden rounded-xl border border-trueGray-200 bg-white last:mb-0 md:mb-0">
      <div className="box-border flex w-full items-center justify-between bg-gray-100 px-4 pb-6 pt-4 md:px-6">
        <div>
          <p className="text-base font-bold lg:text-xl lg:font-semibold">{data.title}</p>
          <p className="text-sm text-trueGray-500 lg:text-base">{data.subTitle}</p>
        </div>
      </div>
      {_.isEmpty(data.data) ? (
        <p className="p-2 text-center text-sm text-trueGray-500 lg:text-lg">
          아쉽게도 해당 년도의 부트캠프 모집 일정은 모두 마감되었습니다. <br />
          내년도 정보가 곧 업데이트될 예정이니 <br />
          조금만 기다려 주시면 감사하겠습니다.
        </p>
      ) : (
        <div
          className={`${hasApplyData ? 'mt-15 lg:mt-[68px]' : 'mt-2'} ${
            hasApplyData && data.data?.length === 1 ? 'pb-6' : ''
          } px-4 md:px-6`}>
          {_.sortBy(data.data, 'applyStartedAt').map(row => (
            <>
              {applyLabel(row) === '모집중' ? (
                <div
                  key={row.id}
                  className="absolute top-[70px] w-[calc(100%-32px)] rounded-lg border border-trueGray-200 bg-white px-4 py-[14px] shadow-[0px_8px_14px_0px_rgba(0,0,0,0.08)] md:w-[calc(100%-48px)] lg:top-[80px] lg:py-3">
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-semibold lg:text-lg">현재 모집 중</p>
                      <p className="text-sm text-trueGray-500 lg:text-base">{row.title}</p>
                    </div>
                    <button
                      onClick={() => {
                        void router.push(`/school/${row.id}`).then(() => window.scrollTo(0, 0))
                      }}
                      className="flex items-center justify-center rounded-md bg-yellow-450 px-3 py-2 lg:px-6 lg:py-[10px]">
                      <span className="text-sm font-semibold text-white lg:text-base">
                        지원하기
                      </span>
                      <ChevronRightIcon width={isTablet ? 16 : 18} className="ml-1 text-white" />
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  key={row.id}
                  className="flex items-center justify-between border-b border-trueGray-200 py-3 last:border-b-0">
                  <div className="text-sm font-semibold lg:text-base">
                    <span>{`${row.id.replace(/\D/g, '')}기 - `}</span>
                    <span>
                      {applyLabel(row) === '모집 예정'
                        ? `${dayjs(row.applyStartedAt).format('YY년 MM월')} 오픈`
                        : '사전 알림'}
                    </span>
                  </div>
                  {applyLabel(row) === '모집 예정' ? (
                    <div className="text-sm text-trueGray-400 lg:text-base">모집 예정</div>
                  ) : (
                    <button
                      onClick={() => {
                        void router.push(
                          {
                            pathname: '/pre-notice',
                            query: { preNoticeId: row.id },
                          },
                          '/pre-notice',
                        )
                      }}
                      className="flex items-center text-sm lg:text-base">
                      <span>사전 알림 신청</span>
                      <ChevronRightIcon width={16} className="ml-1" />
                    </button>
                  )}
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </article>
  )
}

export default SchoolScheduleCard
