import { ChevronRightIcon } from '@heroicons/react/outline'
import { useMediaQuery } from '@mantine/hooks'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

import styles from './KDTPreNoticeBanner.module.scss'

interface IResponsive {
  mobile: string
  tablet: string
  laptop: string
  desktop: string
}
interface IKDTPreNoticeBannerProps {
  imgSrc: IResponsive
}

const KDTPreNoticeBanner: FC<IKDTPreNoticeBannerProps> = ({ imgSrc }) => {
  const isLaptop = useMediaQuery('(min-width: 1024px) and (max-width: 1279px)')
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)')
  const isMobile = useMediaQuery('(max-width: 767px)')
  const renderImg = () => {
    if (isMobile) {
      return imgSrc.mobile
    }

    if (isTablet) {
      return imgSrc.tablet
    }

    if (isLaptop) {
      return imgSrc.laptop
    }

    return imgSrc.desktop
  }

  return (
    <Link href="/pre-notice">
      <div
        className={`${styles.prenoticeBanner} relative w-full cursor-pointer px-4 md:mx-auto md:px-6`}>
        <div className="absolute left-0 top-0 z-0 h-full w-full">
          <Image
            src={renderImg()}
            alt="사전알림"
            objectFit="contain"
            objectPosition="right"
            layout="fill"
          />
        </div>
        <div className=" relative z-10 flex h-full max-w-7xl flex-col pt-4 md:mx-auto md:justify-center md:px-4 lg:px-6">
          <h3 className="mb-1 text-xs font-semibold text-[#072C56] lg:mb-2 lg:text-sm xl:text-base">
            국비지원 부트캠프 사전알림
          </h3>
          <p className="mb-4 text-lg font-semibold md:mb-2 lg:mb-4 xl:mb-5 xl:text-2xl">
            기다리는 부트캠프가 있으신가요?
            <br /> 가장 먼저 모집 소식을 보내 드릴게요
          </p>
          <div className="flex items-center">
            <span className="text-xs font-semibold text-gray-500 lg:text-sm">더 알아보기</span>
            <ChevronRightIcon className="ml-1 w-[14px] text-gray-500 lg:w-4" />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default KDTPreNoticeBanner
